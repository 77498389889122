<script>
import { Actions } from "@/core/services/store/dashboard/dashboard.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ShareWidget",

  async created() {
    this.$router.replace({ name: "dashboard", params: {} });

    const encoded = this.$route.params.hash;
    let hash = JSON.parse(new Buffer(encoded, "base64").toString("utf8"));

    await this.$store.dispatch(Actions.InsertExternalWidget, {
      widget: hash,
      teamId: ApiService.teamId,
    });

    this.$bvToast.toast("Added new widget on your dashboard.", {
      title: "Success",
      variant: "success",
      autoHideDelay: 5000,
    });
  },
};
</script>

<style scoped></style>
